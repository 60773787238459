<template>
  <v-card class="transparent ma-0 pa-2 elevation-0">
    <v-card-title class="lead-title">
      <v-list class="transparent pa-0">
        <v-list-item>
          <v-list-item-avatar class="pa-0">
            <v-icon
              v-if="value.probability === 'HIGHEST'"
              class="mb-1"
              color="success"
              >mdi-chevron-double-up</v-icon
            >
            <v-icon
              v-else-if="value.probability === 'HIGH'"
              class="mb-1"
              color="lime lighten-1"
              >mdi-chevron-up</v-icon
            >
            <v-icon
              v-else-if="value.probability === 'MEDIUM'"
              class="mb-1"
              color="deep-orange darken-2"
              >mdi-chevron-down</v-icon
            >
            <v-icon v-else class="mb-1" color="error"
              >mdi-chevron-double-down</v-icon
            >
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title class="mb-2">
              <a
                class="title-link"
                v-if="value.upworkLead && value.upworkLead.link"
                :href="value.upworkLead.link"
                target="blank"
              >
                {{ value.name }}
              </a>
              <a class="title-link" v-else href="">
                {{ value.name }}
              </a>
            </v-list-item-title>
            <v-list-item-subtitle>
              <v-chip
                small
                class="mr-2"
                label
                outlined
                color="primary"
                v-if="value.analyzeCategory"
                >{{ value.analyzeCategory.name }}</v-chip
              >
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-card-title>
    <v-divider class="ma-4"></v-divider>

    <v-slide-group multiple show-arrows class="pa-2">
      <v-slide-item v-slot="{ active, toggle }" v-if="value.upworkLead">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-chip
              label
              class="mx-2"
              v-bind="attrs"
              v-on="on"
              :input-value="active"
              @click="toggle"
            >
              <v-icon class="mr-2">mdi-currency-usd</v-icon>
              {{ formatPrice(value.upworkLead.estBudget * 100) }}
            </v-chip>
          </template>
          <span>Fixed Price Budget</span>
        </v-tooltip>
      </v-slide-item>
      <v-slide-item
        v-slot="{ active, toggle }"
        v-if="
          value.upworkLead &&
          (value.upworkLead.minHourRate || value.upworkLead.maxHourRate)
        "
      >
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-chip
              label
              class="mx-2"
              v-bind="attrs"
              v-on="on"
              :input-value="active"
              @click="toggle"
            >
              <v-icon class="mr-2">mdi-cash-clock</v-icon>
              {{ formatPrice(value.upworkLead.minHourRate * 100) }}
              -
              {{ formatPrice(value.upworkLead.maxHourRate * 100) }}
            </v-chip>
          </template>
          <span>Hourly Rate</span>
        </v-tooltip>
      </v-slide-item>

      <v-slide-item v-slot="{ active, toggle }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-chip
              label
              class="mx-2"
              :input-value="active"
              v-bind="attrs"
              v-on="on"
              @click="toggle"
            >
              <v-icon class="mr-2">mdi-thermometer</v-icon>

              {{
                (value.valid || value.valid === 0
                  ? value.valid
                  : value.upworkLead &&
                    (value.upworkLead.valid || value.upworkLead.valid === 0)
                  ? value.upworkLead.valid
                  : 0
                ).toFixed(2)
              }}/{{
                (value.invalid || value.invalid === 0
                  ? value.invalid
                  : value.upworkLead &&
                    (value.upworkLead.invalid || value.upworkLead.invalid === 0)
                  ? value.upworkLead.invalid
                  : 0
                ).toFixed(2)
              }}
            </v-chip>
          </template>
          <span>Lead Validity: (Valid/Invalid)</span>
        </v-tooltip>
      </v-slide-item>
    </v-slide-group>

    <v-card-text class="pt-0">
      <!-- Domains -->
      <v-slide-group
        class="my-4 ma-0 pa-0"
        center-active
        show-arrows
        v-if="value.domains && value.domains.length"
      >
        <v-slide-item v-for="rDomain in value.domains" :key="rDomain.id">
          <BusinessDomainItemSmall
            @click="() => $emit('onDomainClick', rDomain.domain)"
            :value="rDomain.domain"
          ></BusinessDomainItemSmall>
        </v-slide-item>
      </v-slide-group>

      <!-- Last History Track -->

      <v-list
        three-line
        v-if="value.history && value.history.length"
        class="transparent pt-0"
      >
        <v-subheader class="pl-0">
          <h3 class="my-2">Last History Track</h3>
        </v-subheader>

        <v-list-item
          class="last-history-track ma-0"
          link
          @click="$emit('onLeadHistoryOpen', value)"
        >
          <v-list-item-avatar>
            <v-tooltip bottom v-if="value.history[0].user">
              <template v-slot:activator="{ on, attrs }">
                <v-avatar
                  v-bind="attrs"
                  v-on="on"
                  :style="{
                    backgroundColor:
                      value.history[0].user.profile.avatar &&
                      value.history[0].user.profile.avatar.url
                        ? 'white'
                        : value.history[0].user.profile.color,
                  }"
                >
                  <img
                    :src="
                      value.history[0].user.profile.avatar &&
                      value.history[0].user.profile.avatar.url
                        ? value.history[0].user.profile.avatar.url
                        : value.history[0].user.profile.avatarURL
                    "
                    :lazy-src="
                      value.history[0].user.profile.avatar &&
                      value.history[0].user.profile.avatar.url
                        ? value.history[0].user.profile.avatar.url
                        : value.history[0].user.profile.avatarURL
                    "
                  />
                </v-avatar>
              </template>
              <span>{{ value.history[0].user.profile.name }}</span>
            </v-tooltip>
            <v-icon v-else color="primary">mdi-circle</v-icon>
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title>{{
              formatDate(value.history[0].created_at)
            }}</v-list-item-title>
            <v-list-item-subtitle>
              <span v-if="value.history[0].status">
                Lead Was Moved to the Status:
                <strong>{{ value.history[0].status.name }}</strong>
              </span>
              <span v-if="value.history[0].comment">
                <span>{{ value.history[0].comment.content }}</span>
              </span>
              <span v-if="value.history[0].proposal">
                <strong>Proposal Created:</strong> : <br />
                <ProposalItemSmall
                  class="mt-2"
                  :value="value.history[0].proposal"
                  @click="(val) => $emit('onProposalClick', val)"
                ></ProposalItemSmall>
              </span>
              <v-tooltip bottom v-if="value.history[0].document">
                <template v-slot:activator="{ on, attrs }">
                  <div
                    v-bind="attrs"
                    v-on="on"
                    class="vue-file-agent grid-block-wrapper"
                    style="padding: 0"
                    @click="$emit('onFileClick', value.history[0].document)"
                  >
                    <strong>File Attached</strong> : <br />

                    <VueFilePreview
                      :value="value.history[0].document"
                      :linkable="false"
                      class="file-preview-wrapper grid-box-item grid-block"
                    ></VueFilePreview>
                  </div>
                </template>
                <span>{{
                  typeof value.history[0].document.name === "string"
                    ? value.history[0].document.name
                    : value.history[0].document.name()
                }}</span>
              </v-tooltip>
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <!-- Lead Information -->

      <h3 class="my-2">Initial Information:</h3>
      <p class="simple-text" v-html="value.initial_info"></p>

      <!-- Services -->
      <v-slide-group
        class="my-4 ma-0 pa-0"
        center-active
        show-arrows
        v-if="value.recommendedServices && value.recommendedServices.length"
      >
        <v-slide-item
          v-for="rService in value.recommendedServices"
          :key="rService.id"
        >
          <ServiceItemSmall
            class="ma-2"
            :value="rService.service"
            @click="() => $emit('onServiceClick', rService.service)"
          ></ServiceItemSmall>
        </v-slide-item>
      </v-slide-group>
    </v-card-text>
  </v-card>
</template>
      
      
  <script>
import { DELIVERABLE_BUTTONS } from "../../../../views/src/apps/service-management/constants/actions.gh";
import { DELIVERABLE_PERMISSIONS } from "../../../../views/src/apps/service-management/constants/permissions.gh";
import { FormatterHelper } from "../../../helpers/formatter.helper";
import { PermissionsHelper } from "../../../helpers/permissions.helper";
import ProposalItemSmall from "../../molecules/sales/ProposalItemSmall.vue";
import BusinessDomainItemSmall from "../knowledgeBase/businessDomains/BusinessDomainItemSmall.vue";
import ServiceItemSmall from "../services/ServiceItemSmall.vue";
// import FormSection from "../../atoms/common/FormSection.vue";

export default {
  props: {
    value: {
      default: () => ({}),
    },
    permissions: {
      type: Object,
      default: () => ({}),
    },
    payload: {
      type: Object,
      default: () => ({}),
    },
  },
  components: {
    // FormSection,
    ServiceItemSmall,
    BusinessDomainItemSmall,
    ProposalItemSmall,
  },
  data: () => ({
    tagIndex: null,
    durationFormatter: FormatterHelper.timeDuration,
    formatPrice: FormatterHelper.price,
    formatDate: FormatterHelper.formatDate,
  }),
  computed: {
    showCustomerFiles: function () {
      return this.permissions[
        DELIVERABLE_PERMISSIONS.ALLOW_SEE_DELIVERABLE_CUSTOMER_DOCUMENTS
      ];
    },
    showPrivateFiles: function () {
      return this.permissions[
        DELIVERABLE_PERMISSIONS.ALLOW_SEE_DELIVERABLE_PRIVATE_DOCUMENTS
      ];
    },
    filesActions: function () {
      return PermissionsHelper.getActions(
        [DELIVERABLE_BUTTONS.FILES_EDITOR],
        this.permissions
      );
    },
  },
  async created() {},
  methods: {},
  watch: {},
};
</script>


<style lang="scss" scoped>
.lead-title {
  text-transform: uppercase;
  display: inline-block;
}

.title-link {
  text-decoration: none;
  color: white;
  transition: color 0.12s ease-in-out;
  font-size: 1.2rem;

  &:hover {
    color: brown;
  }
}

.last-history-track {
  background-color: rgba($color: #ffffff, $alpha: 0.1);
}

.grid-block-wrapper {
  cursor: pointer;
  .grid-block {
    min-width: 80px;
    width: 80px;
  }
}
</style>