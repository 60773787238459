<template>
  <AView
    :value="value"
    :actions="actions"
    :active="lead.id === activeLeadId"
    :name="lead.name"
    ref="lead-editor-view"
    @onHover="onHover"
    @onActivate="(e) => $emit('onActivate', e)"
    @onMaximize="(e) => $emit('onMaximize', e)"
    @onExpand="(e) => $emit('onExpand', e)"
    @onCollapse="(e) => $emit('onCollapse', e)"
    @onClose="(e) => $emit('onClose', e)"
    @action="(action) => action.handler({ from: value, lead })"
  >
    <template v-slot:content="{}">
      <LeadDetailsVue
        :value="lead"
        :permissions="value.permissions"
        @action="(action) => action.handler({ from: value, lead })"
        @onServiceClick="() => onServiceClick(result.service)"
        @onLeadHistoryOpen="onLeadHistoryOpen"
        @onDomainClick="onDomainClick"
        @onProposalClick="onProposalClick"
        @onFileClick="onFileClick"
      ></LeadDetailsVue>
    </template>
  </AView>
</template>
  
  
  <script>
import { mapGetters, mapState } from "vuex";
import { PermissionsHelper } from "../../../../../components/helpers/permissions.helper";
import LeadDetailsVue from "../../../../../components/wad/organisms/leads/LeadDetails.vue";
import AView from "../../../../../components/wad/organisms/layout/A-View.vue";
import { LEAD_BUTTONS } from "../constants/actions.gh";

export default {
  props: {
    value: {
      default: () => ({}),
    },
  },
  components: {
    AView,
    LeadDetailsVue,
  },
  data() {
    return {
      lead: {},
    };
  },
  computed: {
    ...mapState("LeadStore", ["activeLeadId", "displayedLeads"]),
    ...mapGetters("LeadStore", ["leadById"]),
    actions: function () {
      return PermissionsHelper.getActions(
        [
          // LEAD_BUTTONS.GENERAL_EDITOR_MAIN,
          LEAD_BUTTONS.QUESTIONNAIRE_MAIN,
          LEAD_BUTTONS.PROPOSAL_CREATION_MAIN,
          LEAD_BUTTONS.FILES_EDITOR_MAIN,
          LEAD_BUTTONS.HISTORY_MAIN,
        ],
        this.value.permissions
      );
    },
  },
  created() {
    this.$store.dispatch("LeadStore/GetLead", {
      id: this.value.relation.params.leadId,
    });

    this.lead = this.leadById(this.value.relation.params.leadId);
  },
  methods: {
    onHover() {
      this.$store.commit(
        "LeadStore/setActiveId",
        this.value.relation.params.leadId
      );
    },
    onFileClick(document) {
      document.u_type = "documents";

      this.$store.dispatch("FileStore/openFileDetails", {
        from: this.value,
        file: document,
      });
    },
    onProposalClick(proposal) {
      this.$store.dispatch("ProposalsStore/openProposalAssistant", {
        from: this.value,
        lead: this.lead,
        proposal: proposal,
      });
    },
    onLeadHistoryOpen() {
      this.$store.dispatch("LeadStore/openLeadHistory", {
        from: this.value,
        lead: this.lead,
      });
    },
    onServiceClick(service) {
      this.$store.dispatch("ServiceStore/SetActiveService", {
        from: this.value,
        service,
      });
    },
    onTermClick(term) {
      this.$store.dispatch("GlossaryStore/SetActiveTerm", {
        from: this.value,
        term,
      });
    },
    onQuestionClick(question) {
      this.$store.dispatch("QuestionStore/SetActiveQuestion", {
        from: this.value,
        question,
      });
    },
    onDomainClick(businessDomain) {
      this.$store.dispatch("BusinessDomainStore/SetActiveBusinessDomain", {
        from: this.value,
        businessDomain,
      });
    },
  },
  watch: {
    displayedLeads: {
      async handler(newVal) {
        if (newVal) {
          this.lead = this.leadById(this.value.relation.params.leadId);
        }
      },
      deep: true,
    },
  },
};
</script>