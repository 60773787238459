<template>
  <v-chip
    @click.stop.prevent="$emit('click', value)"
    class="mr-2 pa-2"
    color="primary"
    label
    text-color="white"
  >
    <v-icon left> mdi-handshake-outline </v-icon>
    {{ cutFormatter(value.name, 40) }}
  </v-chip>
</template>


<script>
import { FormatterHelper } from "../../../helpers/formatter.helper";

export default {
  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
    permissions: {
      type: Object,
      default: () => ({}),
    },
    payload: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {},
  data() {
    return {
      durationFormatter: FormatterHelper.timeDuration,
      cutFormatter: FormatterHelper.cutString,
      dateFormatter: FormatterHelper.formatDate,

      loading: false,
      checked: false,
    };
  },
  components: {},
};
</script>
